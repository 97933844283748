/* @flow */

import type { InfoBlock } from "shop-state/types";
import type { NewsPost } from "../NewsListItem";

import React, { useContext, useState, useEffect, useMemo } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useData, useSendMessage } from "crustate/react";
import { useBrowser } from "@awardit/react-use-browser";
import { Helmet } from "react-helmet-async";
import { HomeData, CustomerData, CurrentPageInfoData, CurrentInfoListData, PhotoServiceListData, ViewModeData } from "data";
import ProductCard, { DummyCard } from "components/ProductCard";
import ProductCarousel from "components/ProductCarousel";
import HomeHero from "components/HomeHero";
import UploadImagesOverlay from "components/UploadedImages/UploadImagesOverlay";
import NewsListItem from "../NewsListItem";
import Carousel from "components/Carousel";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import { getCurrentPageInfo } from "state/current-page-info";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import { Title, Description, Ingress, Items, Item } from "components/UiComponents";
import { getCustomerData, tryParseJSONObject } from "helpers/utils";
import Agreement from "components/Agreement";
import PopularCategoryCarousel from "components/PopularCategoryCarousel";
import { useTranslate } from "@awardit/react-use-translate";
import useDevice from "helpers/use-device";
import { logout } from "@crossroads/shop-state/customer";
import { awarditAgreementAgree } from "queries";
import { MODE } from "state/view-mode";

import styles from "./styles.scss";

const HomeView = () => {
  const {
    routes,
    content: {
      homeview: content,
      uploadedImagesView: imagesview,
      productCarousel: { useOnHomeView },
      allproductsview: { popularCategories, popularCategoriesTitle },
    },
    configuration,
  } = useContext(StoreInfoContext);
  const isMobile = useDevice("lt", 914);
  const home = useData(HomeData);
  const viewMode = useData(ViewModeData);
  const sendMessage = useSendMessage();
  const t = useTranslate();
  const { width: browserWidth } = useBrowserDimensions();
  const customer = getCustomerData(useData(CustomerData));
  const [showTerms, setShowTerms] = useState(false);
  const isBrowser = useBrowser();
  const client = useClient();
  const currentPageInfoData = useData(CurrentPageInfoData);
  const currentInfoListData = useData(CurrentInfoListData);
  const photoServiceListData = useData(PhotoServiceListData);
  const popularCategoriesJson = popularCategories !== undefined &&
    tryParseJSONObject(popularCategories) ? JSON.parse(popularCategories) : "";
  const memberTargetList = customer &&
    customer.memberTargetList &&
    customer.memberTargetList.list.length > 0 ?
    customer.memberTargetList.list :
    [];

  const currentInfoArray = currentInfoListData.state === "LOADED" &&
  currentInfoListData.data &&
  currentInfoListData.data.length > 0 ? currentInfoListData.data : [];

  const [uploadImgOpen, setUploadImgOpen] = useState(false);
  const cookieConsentIsVisible = viewMode === MODE.COOKIE_CONSENT;

  const onSuccess = () => {
    setUploadImgOpen(false);
  };

  useEffect(() => {
    sendMessage(getCurrentPageInfo("HOMEVIEW"));
    sendMessage(getCurrentPageInfo("NEWSVIEW"));
    sendMessage(getCurrentPageInfo("OFFERLISTVIEW"));
    sendMessage(getCurrentPageInfo("CURRENTINFO6"));
  }, []);

  useEffect(() => {
    if (isBrowser &&
      configuration.showTermsOnFirstLogin === true &&
      customer &&
      customer.awardit.agreementAccepted === false) {
      setShowTerms(true);
    }
  }, [isBrowser, customer, configuration]);

  const postAgreement = async () => {
    await client(awarditAgreementAgree);
  };

  const currentInfoOrder = content.currentInfoOrder ?
    Number.parseInt(content.currentInfoOrder, 10) :
    1;
  const featuredProductsOrder = content.featuredProductsOrder ?
    Number.parseInt(content.featuredProductsOrder, 10) :
    4;
  const uploadImageOrder = content.uploadImageOrder ?
    Number.parseInt(content.uploadImageOrder, 10) :
    5;
  const popularProductsOrder = content.popularProductsOrder ?
    Number.parseInt(content.popularProductsOrder, 10) :
    -1;
  const popularCategoriesOrder = content.popularCategoriesOrder ?
    Number.parseInt(content.popularCategoriesOrder, 10) :
    -1;
  const currentInfo6Order = content.currentInfo6Order ?
    Number.parseInt(content.currentInfo6Order, 10) :
    -1;
  const getItemAmount = (config, defaultAmount) => {
    if (browserWidth > 0) {
      const currentBreakpoint = Object.keys(config).find(breakpoint => {
        return browserWidth <= parseInt(styles[breakpoint], 10);
      });

      return currentBreakpoint ? config[currentBreakpoint] : defaultAmount;
    }

    return 0;
  };

  const newsCurrentInfo = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.newsview &&
    currentPageInfoData.data.newsview.length > 0 ?
    currentPageInfoData.data.newsview.slice(0, 15) : [];

  const newsCurrentInfoChunks: Array<Array<InfoBlock>> = [];

  if (currentPageInfoData.state === "LOADED" && currentPageInfoData.data.newsview && currentPageInfoData.data.newsview.length > 0) {
    for (let i = 0; i < newsCurrentInfo.length; i += 3) {
      newsCurrentInfoChunks.push(newsCurrentInfo.slice(i, i + 3));
    }
  }

  const featuredProductsAmount = getItemAmount({ small: 2, medium: 3 }, 4);
  const featuredProducts = useMemo(() => home.state !== "LOADED" ? [...new Array(4)] :
    home.data.featuredProducts && home.data.featuredProducts.filter(p => !memberTargetList ||
      memberTargetList.includes(p.attributes.awarditTargetId) ||
      !p.attributes.awarditTargetId)
      .sort(() => 0.5 - Math.random())
      .slice(0, featuredProductsAmount
      ), [home.state, featuredProductsAmount]);

  const currentInfoTopArray = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.offerlistview ?
    currentPageInfoData.data.offerlistview : [];

  const currentInfoHomeView = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.homeview &&
    currentPageInfoData.data.homeview.length > 0 ? currentPageInfoData.data.homeview : [];

  const currentInfo6Count = content.currentInfo6Count ?
    Number.parseInt(content.currentInfo6Count, 10) : 0;
  const currentInfo6Array = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.currentinfo6 &&
    currentPageInfoData.data.currentinfo6.length > 0 ?
    (currentInfo6Count > 0 ?
      currentPageInfoData.data.currentinfo6.slice(0, currentInfo6Count) :
      currentPageInfoData.data.currentinfo6) : [];

  const featuredProductsSection = (
    <div key="featuredProducts" className={styles.section}>
      <div className={styles.top}>
        {(content.featuredProductsHeading || content.featuredProductsDescription) && (
          <Ingress className={styles.ingress}>
            {content.featuredProductsHeading && (
              <Title className={styles.title} elem="h2">{content.featuredProductsHeading}</Title>
            )}
            {content.featuredProductsDescription && (
              <Description
                className={styles.description} content={content.featuredProductsDescription} />
            )}
          </Ingress>
        )}
        {content.featuredProductsButtonLink && content.featuredProductsButtonText &&
          <div className={styles.featuredProducts}>
            <Link
              to={content.featuredProductsButtonLink}
              className={cn("link", styles.cta)}
            >
              {content.featuredProductsButtonText}
            </Link>
          </div>
        }
      </div>
      <Items>
        {featuredProducts && featuredProducts.map((p, i) =>
          p ?
            <Item key={p.name}>
              <ProductCard product={p} list={content.featuredProductsHeading ?? ""} position={i} />
            </Item> :
            <Item key={i}><DummyCard /></Item>
        )}
      </Items>
    </div>
  );

  const featuredContentSection = (
    <div key="featuredContent">
      <div className={styles.currentPageInfoTop}>
        {currentPageInfoData.state === "LOADED" && currentInfoTopArray.length > 0 && (
        /* eslint-disable react/no-danger */
          <Carousel
            autoplay
            className={styles.currentInfo}
            items={currentInfoTopArray.map<React$Node>((info, i) => (
              <div
                key={"offerlist_item_" + (info.id ? info.id : "")}
                className={styles.currentInfoCardWrapper} idx={i}
              >
                <div className={styles.currentInfoCard}>
                  <div className={styles.currentInfoCardImageWrapper}>
                    <img src={info.image} />
                  </div>
                  {(info.title || info.content) && (
                    <div className={styles.currentInfoCardContent}>
                      {info.title && (
                        <h2>{info.title}</h2>
                      )}
                      {info.content && (
                        <p
                          className={styles.currentInfoDescription}
                          dangerouslySetInnerHTML={{ __html: info.content }} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))
            }
            slidesToScroll={1}
            slidesToShow={isMobile ? 1 : 2}
            timer={8000}
          />
        )
        /* eslint-enable react/no-danger */
        }
        {currentPageInfoData.state === "LOADED" &&
        currentPageInfoData.data.newsview &&
        currentPageInfoData.data.newsview.length > 0 && (
          <div className={styles.newsListWrapper}>
            {/* Show three items per slide, max 15 items */}
            <Carousel
              autoplay
              className={styles.carousel}
              items={newsCurrentInfoChunks.map((x, i) => (
                <Item key={`newsListItem${i}`} className={styles.newsListItems}>
                  {x.map(x => ({ ...x, link: routes.newsListView && routes.newsListView.url && x.id ? `${routes.newsListView.url}#newsItem-${x.id}` : "" }))
                    .map((x: NewsPost) => (
                      <NewsListItem key={x.title} item={x} className={styles.newsListItem} />
                    ))}
                </Item>
              ))
              }
              slidesToScroll={1}
              slidesToShow={1}
              timer={8000}
            />
            {routes.newsListView && routes.newsListView.url && (
              <Link className={styles.newsListLink} to={routes.newsListView.url}>
                {t("NEWS.SHOW_ALL")}
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );

  const currentPageInfo = (
    <div key="currentPageInfo" className={cn(styles.currentPageInfoBottom, "awardit-currentPageInfoBottom")}>
      {currentPageInfoData.state === "LOADED" && (
      /* eslint-disable react/no-danger */
        <>
          {content.currentInfoTitle &&
            <Title className={styles.title} elem="h2">{content.currentInfoTitle}</Title>
          }
          <div className={styles.currentInfoSectionTop}>
            {currentInfoHomeView.length > 0 &&
              <div className={styles.currentInfoCardsWrapper__left}>
                {currentInfoHomeView.map<React$Node>((info, i) => (
                  (i % 2 === 0) && (
                    <div key={"offerlist_item_" + i} className={styles.currentInfoCardWrapper} idx={i}>
                      <div className={styles.currentInfoCard}>
                        <div>
                          <img src={info.image} />
                        </div>
                        <div className={styles.currentInfoCardContent}>
                          <h2>{info.title}</h2>
                          <p
                            className={styles.currentInfoDescription}
                            dangerouslySetInnerHTML={{ __html: info.content }} />
                        </div>
                      </div>
                    </div>
                  )
                ))}
              </div>
            }
            <div className={styles.currentInfoCardsWrapper__right}>
              {currentInfoHomeView.map<React$Node>((info, i) => (
                (i % 2 === 1) && (
                  <div key={"offerlist_item_" + i} className={styles.currentInfoCardWrapper} idx={i}>
                    <div className={styles.currentInfoCard}>
                      <div>
                        <img src={info.image} />
                      </div>
                      <div className={styles.currentInfoCardContent}>
                        <h2>{info.title}</h2>
                        <p
                          className={styles.currentInfoDescription}
                          dangerouslySetInnerHTML={{ __html: info.content }} />
                      </div>
                    </div>
                  </div>
                )
              ))}
            </div>
          </div>
        </>
      )
        /* eslint-enable react/no-danger */
      }
    </div>
  );
  const currenInfoCardsSection = (
    currentInfoArray &&
    currentInfoArray.length > 0 &&
    <div key="featuredContent" className={cn("awardit-currenInfoCardsSection")}>
      <Wrapper className={styles.currentinfowrapper}>
        <div className={styles.currenInfoCardsSection}>
          {currentInfoArray.map((info, i) => (
          /* eslint-disable react/no-danger */
            <div key={"offerlist_item_" + i} className={styles.currenInfoCardsSectionCardWrapper} idx={i}>
              <div className={cn(styles.currentInfoCard, "awardit-currenInfoCardsSection")}>
                <div>
                  <img src={info.image} />
                </div>
                {info.fromDate &&
                  <p className={styles.currentInfoCardDate}>{info.fromDate}</p>
                }
                <div className={styles.currentInfoCardContent}>
                  <h3>{info.title}</h3>
                  <div
                    className={cn(styles.currentInfoDescription, "awardit-currentInfoDescription")}
                    dangerouslySetInnerHTML={{ __html: info.content }} />
                </div>
              </div>
            </div>
          /* eslint-enable react/no-danger */
          ))}
        </div>
      </Wrapper>
    </div>
  );

  const uploadImageSection = (
    routes.photoServiceView &&
    routes.photoServiceView.toggle !== undefined &&
    routes.photoServiceView.toggle &&
    <Wrapper className={styles.wrapper}>
      <div key="uploadImageSection" className={cn("awardit-homeViewUploadedImages", styles.section)}>
        <UploadImagesOverlay
          open={uploadImgOpen}
          setOpen={setUploadImgOpen}
          onSuccess={onSuccess}
        />
        <div className={cn("awardit-homeViewUploadedImagesTop", styles.top)}>
          <Title className={styles.title} elem="h2">
            {routes.photoServiceView &&
              routes.photoServiceView.title ?
              routes.photoServiceView.title :
              t("IMAGE_UPLOADER.LATEST")}
          </Title>
          <div className={cn("awardit-homeViewUploadedImagesTopCta", styles.uploadImage)}>
            <Link
              to={routes.photoServiceView && routes.photoServiceView.url ? routes.photoServiceView.url : ""}
              className={cn("link", styles.cta)}
            >
              {t("IMAGE_UPLOADER.SHOW_ALL")}
            </Link>
            <Button
              type="button"
              className={styles.btn}
              variant="primary"
              onClick={() => setUploadImgOpen(true)}
            >
              {t("IMAGE_UPLOADER.UPLOAD_IMAGE")}
            </Button>
          </div>
        </div>
        {imagesview &&
          imagesview.frontpageInfo !== null &&
          imagesview.frontpageInfo !== undefined &&
          <>
            {/* eslint-disable react/no-danger */}
            <div
              className={styles.imageViewDesc}
              dangerouslySetInnerHTML={{ __html: imagesview.frontpageInfo }}
            />
            {/* eslint-enable react/no-danger */}
          </>
        }
        <Items>
          {photoServiceListData &&
          photoServiceListData.state === "LOADED" &&
          photoServiceListData.data.length > 0 ?
            photoServiceListData.data.slice(0, 4).map((elem, i) => (
              elem ?
                <Item key={`${elem.title}-${i}`} className={styles.uploadedImageContainer}>
                  <div className={styles.innerImageContainer}>
                    <img src={elem.thumb ? elem.thumb : elem.url} />
                  </div>
                  <p className={styles.imageContainerTitle}>{elem.title}</p>
                </Item> :
                <Item key={i}><DummyCard /></Item>
            )) : <p className={styles.imageContainerTitle}>{t("IMAGE_UPLOADER.EMPTY")}</p>
          }
        </Items>
      </div>
    </Wrapper>
  );

  const additionalHtmlSection = (
    content.additionalHtml && (
      <div className={styles.suppliersBlock}>
        <Wrapper className={styles.suppliersWrapper}>
          {/* eslint-disable react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: content.additionalHtml }} />
          {/* eslint-enable react/no-danger */}
        </Wrapper>
      </div>
    )
  );

  const additionalHtmlSecondarySection = (
    content.additionalHtmlSecondary && (
      <div className={styles.additionalHtmlContainer}>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content.additionalHtmlSecondary }} />
        {/* eslint-enable react/no-danger */}
      </div>
    )
  );

  const popularCategoriesSection = (
    <Wrapper className={styles.popularCategories}>
      {popularCategoriesJson &&
        <PopularCategoryCarousel
          variant="lightText"
          title={popularCategoriesTitle ?? ""}
          popularCategories={popularCategoriesJson}
          className={styles.popularCategoryCarousel}
        />
      }
    </Wrapper>
  );

  const popularProductsSection = (
    Boolean(useOnHomeView) && (
      <Wrapper className={styles.popularProducts}>
        <ProductCarousel />
      </Wrapper>
    )
  );

  const currentInfo6Section = (
    currentInfo6Array &&
    currentInfo6Array.length > 0 &&
    routes.currentInfoView6?.url && (
      <div className="awardit-currenInfoCardsWrapper">
        <Wrapper className={styles.currentinfowrapper}>
          {content.currentInfo6Title &&
            <Title className={styles.title} elem="h2">{content.currentInfo6Title}</Title>
          }
          <div className={cn(styles.currenInfoCardsSection, styles.currenInfoCardsSectionAlt)}>
            {currentInfo6Array.map((info, i) => (
            /* eslint-disable react/no-danger */
              <div key={"currentinfo6_item_" + i} className={cn(styles.currenInfoCardsSectionCardWrapper, styles.currenInfoCardsSectionCardWrapperAlt)} idx={i}>
                <Link
                  to={{
                    pathname: `${(routes.currentInfoView6 && routes.currentInfoView6.url) ?? ""}/${info.id}`,
                    state: { hint: { title: info.title } },
                  }}
                  className={cn(styles.currentInfoCard, styles.currentInfoCardAlt, "awardit-currenInfoCard")}
                >
                  {info.title && <h3>{info.title}</h3>}
                  {info.image &&
                    <div className={styles.currenInfoCardImage}>
                      <img src={info.image} />
                    </div>
                  }
                  {info.content &&
                    <div
                      className={cn(styles.currentInfoDescription, "awardit-currentInfoDescription")}
                      dangerouslySetInnerHTML={{ __html: info.description }}
                    />
                  }
                </Link>
              </div>
            /* eslint-enable react/no-danger */
            ))}
          </div>
        </Wrapper>
      </div>
    )
  );
  const main = [];

  if (featuredProductsOrder >= 0) {
    main[featuredProductsOrder] = featuredProductsSection;
  }

  if (currentInfoOrder >= 0) {
    main[currentInfoOrder] = currentPageInfo;
  }

  if (uploadImageOrder >= 0) {
    main[uploadImageOrder] = uploadImageSection;
  }

  if (popularProductsOrder >= 0) {
    main[popularProductsOrder] = popularProductsSection;
  }

  if (popularCategoriesOrder >= 0) {
    main[popularCategoriesOrder] = popularCategoriesSection;
  }

  if (currentInfo6Order >= 0) {
    main[currentInfo6Order] = currentInfo6Section;
  }

  return (
    <div className={cn(styles.block, "awardit-homeView")}>
      <Helmet
        title={content.pageTitle ?? ""}
      />
      <HomeHero>
        {featuredContentSection}
      </HomeHero>
      <Wrapper className={cn(styles.wrapper, "awardit-homeViewWrapper")}>
        {currenInfoCardsSection}
        { main }
      </Wrapper>
      {additionalHtmlSection}
      {additionalHtmlSecondarySection}

      {showTerms && !cookieConsentIsVisible &&
        <Agreement
          agreementModalOpen={showTerms}
          setAgreementModalOpen={open => {
            if (open === true) {
              sendMessage(logout());
            }

            if (open === false) {
              postAgreement();
              setShowTerms(open);
            }
          }}
        />
      }
    </div>
  );
};

export default HomeView;
