/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { CurrentPageInfoData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import Hero from "components/Hero";
import AllProductsViewHero from "components/AllProductsHero";
import Carousel from "components/Carousel";
import { useLocation } from "react-router";
import OfferListItem from "components/OfferListItem";
import { useBrowser } from "@awardit/react-use-browser";
import InfoCard from "components/CurrentPageInfo/InfoCard";
import { getPageType } from "helpers/utils";

import styles from "./styles.scss";

const medium = Number.parseInt(styles.medium, 10);
const small = Number.parseInt(styles.small, 10);

const CurrentPageInfo = ({
  className,
  showOfferList = false,
}: { className?: string, showOfferList?: boolean }) => {
  const location = useLocation();
  const isBrowser = useBrowser();
  const currentPageInfoData = useData(CurrentPageInfoData);
  const currentPageInfo = currentPageInfoData.state === "LOADED" ? currentPageInfoData.data : null;
  const { routes, content: { redeemview } } = useContext(StoreInfoContext);
  const { width: browserWidth } = useBrowserDimensions();
  const isDesktop = isBrowser && browserWidth >= medium;
  const isMobile = isBrowser && browserWidth < small;

  const page = getPageType(routes, location.pathname);

  if (currentPageInfoData.state !== "LOADED" ||
    (currentPageInfoData.state === "LOADED" && Object.keys(currentPageInfoData.data).length === 0)) {
    return null;
  }

  if (!currentPageInfo) {
    return ["EARNVIEW", "REDEEMVIEW"].includes(page) ?
      <Hero className={cn(styles.banner, styles.hero)} image={null} /> :
      null;
  }

  /*
   * Show hero on earn view if infoList[0]
  */
  if (page === "EARNVIEW" && currentPageInfo.earnview && currentPageInfo.earnview.length > 0) {
    return (
      <Hero
        {...currentPageInfo.earnview[0]}
        className={cn(styles.banner, styles.hero, className)}
      />
    );
  }

  if (page === "ALLPRODUCTSVIEW" && currentPageInfo.allproductsview && currentPageInfo.allproductsview.length > 0) {
    return (
      <AllProductsViewHero
        {...currentPageInfo.allproductsview[0]}
      />
    );
  }

  /*
   * Show hero on redeem view. It uses data
   * from infoList[0] or content.redeemview
  */
  if (page === "REDEEMVIEW" && currentPageInfo.redeemview) {
    const first = currentPageInfo.redeemview[0] || {};

    return (
      <Hero
        className={cn(styles.banner, styles.hero, className)}
        title={first.title || (redeemview.heroHeading ?? "")}
        subTitle={first.title && redeemview.heroSubHeading ? redeemview.heroSubHeading : null}
        content={first.content || (redeemview.heroDescription ?? "")}
        image={first.image || (redeemview.heroImage ?? "")}
      />
    );
  }

  if (page === "HOMEVIEW" && currentPageInfo.homeview) {
    const items = currentPageInfo.homeview.map((info, i, arr) => {
      return (
        <div key={"offerlist_item_homeview_" + i} className={styles.offerListItem}>
          <OfferListItem
            count={arr.length}
            item={{
              title: info.title,
              content: info.content,
              image: info.image,
              link: null,
              linkText: null,
            }}
          />
        </div>
      );
    });

    return (
      <Carousel
        autoplay
        className={cn(styles.carousel, className)}
        /* eslint-disable unicorn/no-nested-ternary */
        slidesToScroll={isDesktop ? 3 : isMobile ? 1 : 2}
        slidesToShow={isDesktop ? 3 : isMobile ? 1 : 2}
        /* eslint-enable unicorn/no-nested-ternary */
        timer={8000}
        items={items}
      />
    );
  }

  if (showOfferList && currentPageInfo.offerlistview) {
    return (
      <div className={className}>
        {currentPageInfo.offerlistview.map<React$Node>((info, i) => <InfoCard key={"offerlist_item_" + i} info={info} idx={i} />)};
      </div>
    );
  }

  return null;
};

export default CurrentPageInfo;
